body{
  background-color: #0f111f;
  max-width: "100%";
  margin: 0;
}



*{
  -ms-overflow-style: none;
  font-family: 'AZN';
}

::-webkit-scrollbar {
  display: none;
}

@font-face{
  font-family: 'Emotion';
  src: url(./theme/EmotionEngine-8ynA.ttf) format('truetype');
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}
